// document.addEventListener("turbolinks:load", () => {
// });

// Open/Close actions/sub-actions
function task_toggle_child_rows_do(elem, value = null) {
  if (value != null) {
    // elem.closest('tr').nextUntil('tr:not(.tablesorter-childRow)').find('td').toggle(value);
    if (value == true) {
      elem.closest('tr').nextUntil('tr:not(.tablesorter-childRow)').find('td').css('display', '')
    }
    else {
      elem.closest('tr').nextUntil('tr:not(.tablesorter-childRow)').find('td').css('display', 'none')
    }
  }
  else {
    elem.closest('tr').nextUntil('tr:not(.tablesorter-childRow)').find('td').toggle();
  }
  // in v2.5.12, the parent row now has the class tablesorter-hasChildRow
  // so you can use this code as well
  // $(this).closest('tr').nextUntil('tr.tablesorter-hasChildRow').find('td').toggle();
}

// Local storage for open/close actions/sub-actions
function set_open_close_states_local_storage(elem_name) {
  console.log('Function OPEN CLOSE ACTIONS=====================>');
  $(elem_name).each(function(){
    var checkb = localStorage.getItem($(this).attr('id'));
    console.log("Set open/close ===========+++++++> " + elem_name);
    console.log($(this).attr('id'))
    if (checkb == 'close') {
      console.log(checkb)
      task_toggle_child_rows_do($(this), false);
    }
  });
}

// Open or Close all sub-actions
function set_open_close_all_actions(oc) {
  if (oc == 'close') {
    $('.task-toggle').each(function(){
      task_toggle_child_rows_do($(this), false);
      localStorage.setItem($(this).attr('id'), 'close');
    });
  }
  else {
    $('.task-toggle').each(function(){
      task_toggle_child_rows_do($(this), true);
      localStorage.removeItem($(this).attr('id'));
    });
  }
}

// Set-up actions/sub-actions functions (load open/close from local storage, add event for open/close on click) / add events for open all & close all
function tasks_ancestry() {
  console.log("IN FUNCTION TASK ANCESTRY !!!!!!!!!!!!!!!!!!!!!!");

  // $('#table-tasks').delegate('.task-toggle', 'click' ,function() {
  $('.task-toggle').on('click', function() {
    console.log('CLICK TASK TOGGLE ' + $(this).attr('id'));

    // save open/close state in localstorage
    if (localStorage.getItem($(this).attr('id')) == null) {
      localStorage.setItem($(this).attr('id'), 'close');
      task_toggle_child_rows_do($(this), false);
    }
    else {
      localStorage.removeItem($(this).attr('id'));
      task_toggle_child_rows_do($(this), true);
    }

    return false;
  });

  //

  $('#tasks-open-all').click(function(){
    set_open_close_all_actions('open');
  });

  $('#tasks-close-all').click(function(){
    set_open_close_all_actions('close');
  });

  set_open_close_states_local_storage('.task-toggle');
}

export { tasks_ancestry }
